import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { createSelector } from "@reduxjs/toolkit";
import {LOAD_FAILED, LOAD_IDLE, LOAD_PROGRESS, LOAD_SUCCESS} from "./loadActionTypes";
import {IStore} from "./store";
import {IIndex} from "../interfaces/Index.interface";

export interface IIndexState {
  indexes: IIndex[];
  status: string;
  error: string | undefined;
}

const initialState: IIndexState = {
  indexes: [],
  status: LOAD_IDLE,
  error: undefined
}

export const fetchIndexes = createAsyncThunk(
  'indexes/fetchIndexes',
  async () => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/index/`);
    return  await response.json();
  }
);

const indexesSlice = createSlice({
  name: 'indexes',
  initialState,
  reducers: {
  },
  extraReducers(builder) {
    builder
      .addCase(fetchIndexes.pending, (state) => {
        state.status = LOAD_PROGRESS;
      })
      .addCase(fetchIndexes.fulfilled, (state, action) => {
        state.indexes = action.payload;
        state.status = LOAD_SUCCESS;
      })
      .addCase(fetchIndexes.rejected, (state, action) => {
        state.status = LOAD_FAILED;
        state.error = action.error.message;
      })
  }
});

export default indexesSlice.reducer;

export const selectAllIndexes = (state: IStore) => state.indexes.indexes;

export const selectIndexesByType = createSelector(
  [selectAllIndexes, (state, typeId) => typeId], (indexes, typeId) => indexes.filter(index => index.idx_type === typeId)
);
