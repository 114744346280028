import React from 'react';
import {Link} from "react-router-dom";
import './IndexTile.scss';

interface IProps {
    name: string,
    path: string,
    count: number,
    selected?: boolean,
    className?: string
}

const IndexTile: React.FC<IProps> = ({name, path, count, selected=false, className: string}: IProps) => {
  return (
    <Link className={"index-tile p-2 me-3" + (selected ? ' active' : '')} to={path}>
      <span className="index-name">{name}</span>
      <span className="index-count">{count} companies</span>
    </Link>
  );
}

export default IndexTile;
