import React from 'react';
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";

export default function DropdownWrapper({items, selected, onChange}) {
  return (
    <>
      <UncontrolledDropdown>
        <DropdownToggle caret color="primary">{selected.name} ({selected.count})</DropdownToggle>
        <DropdownMenu>
          {
            items.map(item => (
              <DropdownItem key={item.id} onClick={() => onChange(item)}>{item.name} ({item.count})</DropdownItem>
            ))
          }
        </DropdownMenu>
      </UncontrolledDropdown>
    </>

  )
}
