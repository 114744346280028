import React, {useEffect, useState} from 'react';
import IndexTile from "./IndexTile/IndexTile";
import './indexes.scss';
import {Helmet} from "react-helmet";
import {Col} from "reactstrap";

export interface IIndex {
  id: number;
  name: string;
  shorthand: string;
  companies: number;
  idx_type: 'index' | 'exchange';
}

const Indexes: React.FC = () => {
  const location = window.location.pathname.indexOf('indexes') > -1 ? 'Indexes' : 'Exchanges' ;
  const [items, setItems] = useState([]);
  const [filter, setFilter] = useState<'index' | 'exchange' | null>(null);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/index`)
      .then(res => res.json())
      .then((result) => {
          setItems(result)
        });

    if (location === 'Indexes') {
      setFilter('index');
    } else {
      setFilter('exchange');
    }

  }, [location]);

  return (
    <>
      <Helmet>
        <title>{`${location} on Ticker Study`}</title>
        <meta
          name="description"
          content={`The list of ${location} on Ticker Study`}
        />
      </Helmet>

      <h2>{location}</h2>

      <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4">
        {items.filter((idx: IIndex) => idx.idx_type === filter).map((item: IIndex) => (
          <Col key={item.id} className="col-md-3">
            <IndexTile
              name={item.name}
              path={`/index/${item.shorthand}/page/1`}
              count={item.companies}
              className={'mr-2'}
            />
          </Col>
        ))}
      </div>
    </>
  );
}

export default Indexes;
