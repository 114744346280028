import React, { useEffect, useState } from 'react';
import {useParams, useLocation, Link} from "react-router-dom";
import { Col, Row } from "reactstrap";
import './Company.scss';
import { Helmet, HelmetProvider } from "react-helmet-async";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faHouse } from '@fortawesome/free-solid-svg-icons';
import CompanyInfo from "./CompanyInfo/CompanyInfo";
import CompanyLinks from "./CompanyLinks/CompanyLinks";
import {ICompany} from "../../../interfaces/company.interface";

const Company: React.FC = () => {
  const location = useLocation();
  let showBack = false;
  if (location.state?.prevPath) {
    showBack = true;
  }

  const initialState: ICompany = {
    id: 0,
    addresses: [],
    cik: 0,
    description: '',
    ir_website: '',
    is_logo: false,
    name: '',
    sic: 0,
    sic_group: 0,
    sic_group_name: '',
    sic_name: '',
    ticker: '',
    website: '',
    wiki_page: ''
  }

  const [company, setCompany] = useState(initialState);

  let { companyId } = useParams();

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/company/${companyId}`)
      .then(res => res.json())
      .then((result) => {
        setCompany(result)
      })
  }, [companyId]);


  return (
    <>
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8"/>
          <title>{company.name} on Ticker Study</title>
          <meta
            name="description"
            content={`Company information about ${company.name} on Ticker Study`}
          />
        </Helmet>
      </HelmetProvider>
      <Row>
        <Col xs={12} md={8} lg={9}>
          <Row>
            <Col xs={12}>
              <div className={'company-title-wrapper'}>
                {/*TODO: Figure out overriding class*/}
                <h2 className="company-title-element">
                  <span className="ticker-badge me-3">{company.ticker}</span>
                  <span className="company-name">{company.name}</span>
                </h2>
              </div>
            </Col>
          </Row>
          <Row className="d-block d-md-none">
            <Row>
              <Col xs={6} md={4} lg={3}>
                <div className="logo-wrapper mb-4">
                  {
                    company.is_logo &&
                    <img className="img-thumbnail" src={`${process.env.REACT_APP_ASSETS_URL}logos/${company.ticker}.png`} alt={`${company.name} logotype`} />
                  }
                </div>
              </Col>
            </Row>
            <Row className="mt-3 mb-4">
              <CompanyLinks company={company} />
            </Row>
          </Row>
          <CompanyInfo company={company} />
        </Col>
        <Col xs={6} md={4} lg={3} className="d-none d-md-block">
          <Row>
            <Col xs={12}>
              <div className="logo-wrapper mb-4">
                {
                  company.is_logo &&
                  <img className="img-thumbnail" src={`${process.env.REACT_APP_ASSETS_URL}logos/${company.ticker}.png`}
                       alt={`${company.name} logotype`}/>
                }
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <CompanyLinks company={company} />
          </Row>
        </Col>
    </Row>
  <Row>
    <Col className="mt-4">
      <p>{company.description}</p>
    </Col>
  </Row>
  <Row>
    <Col xs={4} className={'mt-3 text-end'}>
      {showBack && <Link className="btn btn-primary btn-sm" to={location.state?.prevPath}><FontAwesomeIcon icon={faArrowLeft} /> Back</Link> }
        </Col>
        <Col xs={4} className={'mt-3 text-center'}>
          <Link className="btn btn-primary btn-sm" to="/"><FontAwesomeIcon icon={faHouse} /> Home</Link>
        </Col>
      </Row>
    </>
  );
}

export default Company;
