import React, {useState} from "react";
import './navigation.scss'
import {Navbar, NavbarBrand, Nav, NavItem, Collapse, Col, NavbarToggler, Button} from "reactstrap";
import {NavLink} from "react-router-dom";
import SearchModal from "../Search/SearchModal/SearchModal";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function Navigation() {
  const [isOpen, setIsOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  const toggleModal = (state: boolean) => {
    setModalOpen(state);
  }

  return (
    <>
      <Col className={'navigation-main-wrapper'}>
        <Navbar color="light" light expand="md">
          <NavbarBrand href="/">
            <div className="ts-logo-wrapper">
              <img src={'/ticker-study-logo.png'} alt={'Ticker Study Logotype'}/>
            </div>
          </NavbarBrand>
          <div className={'nav-wrapper row'}>
            <Col className={''}>
              <Button className="btn btn-link btn-search d-block d-md-none" onClick={(event) => {event.preventDefault(); toggleModal(true); }}>
                <FontAwesomeIcon icon={faSearch} />
              </Button>
            </Col>
            <Col>
              <NavbarToggler onClick={toggle}/>
            </Col>
          </div>
          <Collapse isOpen={isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <NavLink className="nav-link" to={'/'} onClick={() => (setIsOpen(false))}>Home</NavLink>
              </NavItem>
              <NavItem>
                <NavLink className="nav-link" to={'/sic'} onClick={() => (setIsOpen(false))}>Industries</NavLink>
              </NavItem>
              <NavItem>
                <NavLink className="nav-link" to={'/indexes'} onClick={() => (setIsOpen(false))}>Indexes</NavLink>
              </NavItem>
              <NavItem>
                <NavLink className="nav-link" to={'/exchanges'} onClick={() => (setIsOpen(false))}>Exchanges</NavLink>
              </NavItem>
              <NavItem>
                <NavLink className="nav-link" to={'/about'} onClick={() => (setIsOpen(false))}>About</NavLink>
              </NavItem>
            </Nav>
          </Collapse>
          <Button className="btn btn-link btn-search d-none d-md-block" onClick={(event) => {event.preventDefault(); toggleModal(true); }}>
            <FontAwesomeIcon icon={faSearch} />
          </Button>
        </Navbar>
        <SearchModal modalOpen={modalOpen} onModal={toggleModal} />
      </Col>
    </>
  );
}
