import React from 'react';
import {Helmet, HelmetProvider} from "react-helmet-async";
import {
  Col,
  Row,
} from "reactstrap";
import {Outlet} from "react-router-dom";
import {useSelector} from "react-redux";
import './GroupPage.scss';
import GroupSection from "./GroupSection/GroupSection";
import IndustrySection from "./IndustrySection/IndustrySection";
import {IStore} from "../../../../store/store";

const GroupPage: React.FC = () => {
  const selectedGroup = useSelector((state: IStore) => state.sic.selected);

  return (
    <Row>
      <HelmetProvider>
        <Helmet>
          <title>SIC Groups on Ticker Study</title>
          <meta
            name="description"
            content={`Browse companies by SIC Groups and Industries on Ticker Study`}
          />
        </Helmet>
      </HelmetProvider>
      <Col className={'group-wrapper'}>
        <Row>
          <Col>
            <h2 className={'mb-2'}><abbr title="Standard Industrial Classification">SIC</abbr> Groups</h2>

            <p>Browse companies by <abbr title="Standard Industrial Classification">SIC</abbr> Groups and
              Industries. You can learn more about SIC on <a href="https://en.wikipedia.org/wiki/Standard_Industrial_Classification" target="_blank" rel="noopener noreferrer">
                Wikipedia</a> or on <a href="https://www.sec.gov/corpfin/division-of-corporation-finance-standard-industrial-classification-sic-code-list" target="_blank" rel="noopener noreferrer">SEC</a> websites.
            </p>
          </Col>
        </Row>
        <GroupSection />
        <Row>
          <Col xs={12} className="d-none d-md-block">
            { selectedGroup &&
              <h3 className={'mb-3 mt-4'}>Industries Within <span className={'group-name'}>{selectedGroup.name}</span> Group</h3>
            }
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={4}>
            <IndustrySection />
          </Col>
          <Col xs={12} md={8}>
            <Outlet/>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default GroupPage;
