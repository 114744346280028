import React from "react";
import GroupPage from "./GroupPage/GroupPage";
import IndustryPage from "./IndustryPage/IndustryPage";

const SicRouter = [
  {
    path: '/sic',
    element: <GroupPage />
  },
  {
    path: '/sic/:groupId',
    element: <GroupPage />,
    children: [
      {
        path: 'industry/:industryId',
        element: <IndustryPage />,
        children: [
          {
            path: 'page/:pageId',
            element: <IndustryPage />
          }
        ]
      }
    ]
  }
];

export default SicRouter;
