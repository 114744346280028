import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {LOAD_FAILED, LOAD_IDLE, LOAD_PROGRESS, LOAD_SUCCESS} from './loadActionTypes';
import {IStore} from "./store";

export interface ISic {
  count: number,
  id: number,
  name: string,
  slug: string
}

export interface ISicState {
  sic: ISic[],
  selected: ISic | null,
  status: string,
  error: string | undefined
}

const initialState: ISicState = {
  sic: [],
  selected: null,
  status: LOAD_IDLE,
  error: undefined
}

export const fetchSic = createAsyncThunk(
  'sic/fetchSic',
  async () => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/sic/`);
    return  await response.json();
  }
);

const sicSlice = createSlice({
  name: 'sic',
  initialState,
  reducers: {
    setSelectedGroup: (state, action) => {
      state.selected = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchSic.pending, (state) => {
        state.status = LOAD_PROGRESS;
      })
      .addCase(fetchSic.fulfilled, (state, action) => {
        state.sic = action.payload;
        state.status = LOAD_SUCCESS;
      })
      .addCase(fetchSic.rejected, (state, action) => {
        state.status = LOAD_FAILED;
        state.error = action.error.message;
      })
  }
});


export const sicReducer = sicSlice.reducer;

export const selectAllSic = (state: IStore) => state.sic.sic;

export const {setSelectedGroup} = sicSlice.actions;
