import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import ListOfCompanies from "../../../Shared/ListOfCompanies/ListOfCompanies";

const IndexCompanies: React.FC = ()=> {
  const [items, setItems] = useState([]);
  let { pageId, indexId } = useParams();

  const [pages, setPages] = useState(0);
  const pageSize= 12;
  const [currentPage, setCurrentPage] = useState(Number(pageId));
  const navigate = useNavigate();


  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/index/${indexId}/companies?page=${currentPage}&page_size=${pageSize}`)
      .then(res => res.json())
      .then((response) => {
        setPages(Math.ceil(response.total/pageSize));

        setItems(response.data);
      });

  }, [indexId, currentPage]);

  const handlePageClick = (newPage: {selected: number}, dismissModal = false) => {
    setCurrentPage(newPage.selected + 1);
    navigate(`/index/${indexId}/page/${newPage.selected + 1}`);
  }

  return (
    <>
      <ListOfCompanies items={items} pages={pages} currentPage={currentPage} onPageClick={handlePageClick} />
    </>
  );
}

export default IndexCompanies;
