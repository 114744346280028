import React, {useEffect} from 'react';
import {useSelector} from "react-redux";
import {Col, Row, Spinner} from "reactstrap";
import {useNavigate, useParams} from "react-router-dom";
import {fetchSic, ISic, selectAllSic, setSelectedGroup} from "../../../../../store/sicSlice";
import {LOAD_FAILED, LOAD_IDLE, LOAD_PROGRESS, LOAD_SUCCESS} from "../../../../../store/loadActionTypes";
import IndexTile from "../../../Indexes/IndexTile/IndexTile";
import DropdownWrapper from "../../../../Shared/DropdownWrapper/DropdownWrapper";
import {IStore} from "../../../../../store/store";
import useAppDispatch from "../../../../../hooks/useAppDispatch";


const GroupSection: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  let { groupId , industryId, pageId} = useParams();

  let sicContent;
  let sicContentMobile;

  const sic = useSelector(selectAllSic);
  const selectedGroup = useSelector((state: IStore) => state.sic.selected);
  const sicStatus = useSelector((state: IStore) => state.sic.status);
  const sicError = useSelector((state: IStore) => state.sic.error);

  const selectGroup = (group: ISic) => {
    navigate(`/sic/${group.slug}`);
  }

  useEffect(() => {
    if (sicStatus === LOAD_IDLE) {
      dispatch(fetchSic());
    }

    if (!groupId && sicStatus === LOAD_SUCCESS) {
      navigate(`/sic/${sic[0].slug}`);
    }

    if (groupId && sicStatus === LOAD_SUCCESS) {
      dispatch(setSelectedGroup(sic.find(sic => sic.slug === groupId)));
    }
  }, [groupId, industryId, pageId, navigate, sicStatus, dispatch, sic]);

  if (sicStatus === LOAD_PROGRESS) {
    sicContent = <Col><Spinner color={'primary'}>Loading...</Spinner></Col>;
    sicContentMobile = sicContent;
  } else if (sicStatus === LOAD_SUCCESS) {

    sicContent = sic && selectedGroup && sic.map((item: ISic)  => (
      <Col key={item.id}>
        <IndexTile name={item.name} path={`/sic/${item.slug}`} count={item.count} selected={item.slug === groupId}/>
      </Col>
    ));

    sicContentMobile = selectedGroup &&
      <DropdownWrapper items={sic} selected={selectedGroup} onChange={selectGroup} />
  } else if (sicStatus === LOAD_FAILED) {
    sicContent = <div>{sicError}</div>
    sicContentMobile = sicContent;
  }

  return (
    <Row>
      <Col className="d-block d-sm-none">
        {sicContentMobile}
      </Col>
      <Col className="d-none d-sm-block">
        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4">
          {sicContent}
        </div>
      </Col>
    </Row>
  );
}

export default GroupSection;
