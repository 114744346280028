import { configureStore } from '@reduxjs/toolkit';
import indexesReducer, {IIndexState} from './indexesSlice';
import { sicReducer, ISicState } from './sicSlice';
import industryReducer, {IIndustryState} from './industriesSlice';

export interface IStore {
  indexes: IIndexState,
  sic: ISicState,
  industries: IIndustryState
}

const store = configureStore({
  reducer: {
    indexes: indexesReducer,
    sic: sicReducer,
    industries: industryReducer,
  }
});

export type AppDispatch = typeof store.dispatch;
export default store;
