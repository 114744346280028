import React from "react";
import './CompanyInfo.scss';
import {Col, Row} from "reactstrap";
import {ICompany} from "../../../../interfaces/company.interface";
import {IAddress} from "../../../../interfaces/address.interface";

interface IProps {
  company: ICompany;
}

const CompanyInfo: React.FC<IProps> = ({company}: IProps)=> {

  function Address({address}: {address: IAddress}) {
    return <div key={address.id}>
      {address.type === 'mailing' ? 'Mailing ' : 'Business '}
      Address: <span className="fw-bold">{address.street1}, {address.street2 ? `${address.street2}, ` : ''} {address.city}, {address.state_or_country}, {address.zip_code}</span>
    </div>;
  }

  function Addresses({addresses}: {addresses: IAddress[]}) {
    return <div  className={'mb-1'}>
      {
        addresses.map(address => (
          <Address key={address.id} address={address} />
        ))
      }
    </div>
  }

  return (
    <Row>
      <Col xs={12}>
        <div className="company-info-wrapper">
          <div>
            CIK: <span className="fw-bold">{company.cik}</span>
          </div>
          <div>
            Ticker: <span className="fw-bold">{company.ticker}</span>
          </div>
          <div>
            SIC Group: <span className="fw-bold">{company.sic_group_name}</span></div>
          <div>
            SIC: <span className="fw-bold">{company.sic_name}</span> ({company.sic})
          </div>
          <div>
            {
              company.addresses && <Addresses addresses={company.addresses} />
            }
          </div>
        </div>
      </Col>
    </Row>
  )
}

export default CompanyInfo;
