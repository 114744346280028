import React, {useEffect, useState} from 'react';
import {Helmet, HelmetProvider} from "react-helmet-async";
import {useNavigate, useParams} from "react-router-dom";
import {Col, Spinner} from "reactstrap";
import ListOfCompanies from "../../../Shared/ListOfCompanies/ListOfCompanies";
import {LOAD_FAILED, LOAD_IDLE, LOAD_PROGRESS, LOAD_SUCCESS} from "../../../../store/loadActionTypes";

const IndustryPage: React.FC = () => {
  const pageSize= 12;

  const { groupId, industryId, pageId } = useParams();
  const [ currentPage, setCurrentPage ] = useState(Number(pageId));
  const [ status, setStatus ] = useState(LOAD_IDLE);
  const [ companies, setCompanies ] = useState([]);
  const [ pages, setPages ] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    setCurrentPage(Number(pageId));
    setStatus(LOAD_PROGRESS);
    fetch(`${process.env.REACT_APP_API_URL}/sic/industry/${industryId}/companies?page=${pageId}&page_size=${pageSize}`)
      .then(res => {
        if (!res.ok) {
          throw new Error(res.statusText);
        }
        return res.json();
      })
      .then((result) => {
        setPages(Math.ceil(result.total/pageSize));
        setCompanies(result.data);
        setStatus(LOAD_SUCCESS);
      })
      .catch((error) => {
        console.error('Error:', error); // Handle the error accordingly
        setStatus(LOAD_FAILED);
      });
  }, [industryId, pageId, navigate]);

  const handlePageClick = (newPage:{selected: number}) => {
    setCurrentPage(newPage.selected + 1);
    navigate(`/sic/${groupId}/industry/${industryId}/page/${newPage.selected + 1}`);
  }

  const content = () => {
    if (status === LOAD_PROGRESS) {
      return (<Col className="text-center">
        <Spinner color={'primary'}>Loading...</Spinner>
      </Col>);
    } else if (status === LOAD_SUCCESS) {
      return (companies && <ListOfCompanies
        items={companies}
        pages={pages}
        currentPage={currentPage}
        onPageClick={handlePageClick} /> );
    } else if (status === LOAD_FAILED) {
      return (<div className="text-danger">Request failed.</div>);
    }
  }

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Industries on Ticker Study</title>
          <meta
            name="description"
            content={`The list of indexes on Ticker Study`}
          />
        </Helmet>
      </HelmetProvider>
      {content()}
    </>
  )
}

export default IndustryPage;
