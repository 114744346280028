import React from 'react';
import { Link, useLocation } from "react-router-dom";
import './CompanyTile.scss';
import {ICompany} from "../../../interfaces/company.interface";

interface ICompanyTileProps {
  companyData: ICompany;
}

const CompanyTile:React.FC<ICompanyTileProps> = ({companyData}: ICompanyTileProps) => {
  const location = useLocation();

  return (
    <div className="company-tile mb-4 p-2">
      <Link to={'/company/' + companyData.ticker} state={{prevPath: location.pathname}}>
        <div className="logo-wrapper">
          {
            companyData.is_logo && <img className="img-thumbnail" src={`${ process.env.REACT_APP_ASSETS_URL}logos/${companyData.ticker}.png`} alt={`${companyData.name} logotype`}/>
          }
        </div>
        <div className="title-wrapper">
          <span className="company-name">{companyData.name}</span>< br/>
          <span className="ticker-badge">{companyData.ticker}</span>
        </div>
      </Link>
    </div>
  );
}

export default CompanyTile;
