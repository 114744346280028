import React from 'react';
import Navigation from "../Navigation/Navigation";

export default function Header() {
  return (
    <>
      <Navigation />
    </>
  );
}
