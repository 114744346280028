import React from 'react';
import {Col} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGlobe, faPersonChalkboard} from "@fortawesome/free-solid-svg-icons";
import {faWikipediaW} from "@fortawesome/free-brands-svg-icons";
import {ICompany} from "../../../../interfaces/company.interface";

interface ICompanyLinksProps {
    company: ICompany;
}

const CompanyLinks: React.FC<ICompanyLinksProps> = ({company}: ICompanyLinksProps) => {
  return (
    <>
      <Col xs={12} sm={12}>
        <a className="btn btn-link btn-sm" href={company.website} target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faGlobe}/> Website
        </a>
      </Col>
      <Col xs={12} sm={12}>
        <a className="btn btn-link btn-sm" href={company.ir_website} target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faPersonChalkboard}/> Investor Relations
        </a>
      </Col>
      <Col xs={12} sm={12}>
        <a className="btn btn-link btn-sm" href={`https://en.wikipedia.org/${company.wiki_page}`} target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faWikipediaW}/> Wikipedia
        </a>
      </Col>
    </>
  );
}

export default CompanyLinks;
