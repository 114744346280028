import React, {useCallback, useEffect, useState} from "react";
import { Input, InputGroup, InputGroupText, Modal, ModalBody, ModalHeader } from "reactstrap";
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './SearchModal.scss';
import SearchResults from '../SearchResults/SearchResults';
import {ISearchResults, ISearchResultsResponse} from "../../../../interfaces/SearchResults.interface";

let debounceTimer: ReturnType<typeof setTimeout>;

interface IProps {
  modalOpen: boolean;
  onModal: (open: boolean) => void;
}

const SearchModal: React.FC<IProps> = ({modalOpen, onModal}) => {
  const [searchInput, setSearchInput] = useState<string>('');
  const [results, setResults] = useState<ISearchResults[]>([]);

  const toggle = () => {
    onModal(!modalOpen);
    setResults([]);
    setSearchInput('');
  }

  const fetchData = useCallback( async () => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/search/${searchInput}`);

    const data: ISearchResultsResponse = await response.json();

    setResults(data.response.docs);
  }, [searchInput])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setSearchInput(event.target.value);
  }

  const closeModal = () => {
    onModal(false);
    setResults([]);
    setSearchInput('');
  }


  useEffect(() => {
    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }

    debounceTimer = setTimeout(() => {
      if (searchInput) {
        fetchData();
      } else {
        setResults([]);
      }
    }, 500);

    return () => clearTimeout(debounceTimer);
  }, [searchInput, fetchData]);

  return (
    <div>
      <Modal isOpen={modalOpen} toggle={toggle} size={'lg'}>
        <ModalHeader toggle={toggle}>
          Search TickerStudy.com
        </ModalHeader>
        <ModalBody>
          <InputGroup size="lg">
            <InputGroupText>
              <FontAwesomeIcon icon={faSearch} />
            </InputGroupText>
            <Input type="search"
                   placeholder="Search..."
                   onChange={handleChange}/>
          </InputGroup>
         <SearchResults myResults={results} pattern={searchInput} onSelect={() => closeModal()}/>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default SearchModal;
