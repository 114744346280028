const LOAD_IDLE = 'idle';
const LOAD_PROGRESS = 'loading';
const LOAD_SUCCESS = 'succeeded';
const LOAD_FAILED = 'succeeded';

export {
  LOAD_IDLE,
  LOAD_PROGRESS,
  LOAD_SUCCESS,
  LOAD_FAILED
}
