import React from 'react';
import {Col, Row} from "reactstrap";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSquareXTwitter, faSquareInstagram} from "@fortawesome/free-brands-svg-icons";
import './Footer.scss';

export default function Footer() {
  return (
    <>
    <Row className="footer-wrapper">
      <Col xs={12} className="mt-3">
        <Row>
          <Col xs={12}>
            <div className="ts-logo-wrapper text-center">
              <img src={'/ticker-study-logo-bw.png'} alt={'Ticker Study Logotype'}/>
            </div>
          </Col>
        </Row>
        <Row className="mt-3 site-links">
          <Col xs={12} className="text-center">
            <ul className={'list-inline d-none d-md-block'}>
              <li className={'list-inline-item'}>
                <Link to="/">Home</Link>
              </li>
              <li className={'list-inline-item ms-2'}>
                <Link to="/sic">Industries</Link>
              </li>
              <li className={'list-inline-item ms-2'}>
                <Link to="/indexes">Indexes</Link>
              </li>
              <li className={'list-inline-item ms-2'}>
                <Link to="/exchanges">Exchanges</Link>
              </li>
              <li className={'list-inline-item ms-2'}>
                <Link to="/about">About</Link>
              </li>
            </ul>
            <ul className={'list-unstyled d-md-none'}>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/sic">Industries</Link>
              </li>
              <li>
                <Link to="/indexes">Indexes</Link>
              </li>
              <li>
                <Link to="/exchanges">Exchanges</Link>
              </li>
              <li>
                <Link to="/about">About</Link>
              </li>
            </ul>
          </Col>
        </Row>
        <Row className="mt-2 mb-4">
          <Col xs={12} className='text-center social-links'>
            <a href="https://x.com/tickerstudy" className="me-3" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faSquareXTwitter}/>
            </a>
            <a href="https://instagram.com/tickerstudy" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faSquareInstagram} />
            </a>
          </Col>
        </Row>
      </Col>
    </Row>
    </>
  );
}
