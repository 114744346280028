import React, {useState} from "react";
import ReactPaginate from "react-paginate";
import {Button, ButtonGroup, Modal, ModalBody, ModalHeader, Row} from "reactstrap";

export default function Pagination({pages, currentPage, onPageClick}) {
  const [ modal , setModal ] = useState(false);
  const toggle = () => setModal(!modal);

  const handlePageClick = (newPage, dismissModal = false) => {
    if (dismissModal) {
      setModal(false);
    }
    onPageClick(newPage);
  }

  return (
    <>
      <div className="d-none d-md-block text-center">
        <div className="d-pagination-wrapper mt3">
          {
            pages > 0 &&
            <ReactPaginate
              className='pagination mt-3'
              pageClassName='page-item'
              pageLinkClassName='page-link'
              nextClassName='page-item'
              nextLinkClassName='page-link'
              previousClassName='page-item'
              previousLinkClassName='page-link'
              activeClassName='active'
              breakLabel="..."
              breakClassName='page-item'
              breakLinkClassName='page-link'
              nextLabel="Next"
              onPageChange={handlePageClick}
              forcePage={currentPage - 1}
              pageRangeDisplayed={3}
              pageCount={pages}
              previousLabel="Previous"
              renderOnZeroPageCount={null}
            />
          }
        </div>
      </div>
      <div className="d-block d-md-none text-center">
        <ButtonGroup>
          <Button color="secondary" outline onClick={() => handlePageClick({selected: currentPage - 2})}
                  disabled={currentPage === 1}>Prev</Button>
          <Button color="secondary" outline onClick={toggle}>{currentPage}</Button>
          <Button color="secondary" outline onClick={() => handlePageClick({selected: currentPage})}
                  disabled={currentPage === pages}>Next</Button>
        </ButtonGroup>
      </div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Select page</ModalHeader>
        <ModalBody>
          <Row className="row-cols-3 row-cols-md-4 row-cols-lg-6">
            {
              [...Array(pages)].map((_, pageNumber) => (
                <div key={pageNumber} className="col text-center">
                  <Button
                    className="mb-2"
                    color="secondary"
                    outline
                    active={(pageNumber + 1) === currentPage}
                    onClick={() => handlePageClick({selected: pageNumber}, true)}>
                    {pageNumber + 1}
                  </Button>
                </div>
              ))
            }
          </Row>
        </ModalBody>
      </Modal>
    </>
  )
}
