import { createSlice, createAsyncThunk, createSelector, PayloadAction } from '@reduxjs/toolkit';
import { LOAD_FAILED, LOAD_IDLE, LOAD_PROGRESS, LOAD_SUCCESS } from "./loadActionTypes";
import { IStore } from "./store";

export interface IIndustry {
  id: number;
  name: string;
  slug: string;
  sic: number;
  count: number;
}

export interface IIndustryState {
  industries: { [key: string]: IIndustry[] };
  selected: IIndustry | undefined;
  status: string;
  error: string | null;
}

const initialState: IIndustryState = {
  industries: {},
  selected: undefined,
  status: LOAD_IDLE,
  error: null,
};

// Async Thunk to fetch industries
export const fetchIndustries = createAsyncThunk(
    'sic/fetchIndustries',
    async (groupId: string, { getState }) => {
      const state = getState() as IStore;
      const industries = state.industries;

      if (industries.industries[groupId]) {
        return industries.industries[groupId];
      }

      const response = await fetch(`${process.env.REACT_APP_API_URL}/sic/${groupId}`);
      return await response.json();
    }
);

const industrySlice = createSlice({
  name: 'industries',
  initialState,
  reducers: {
    setSelectedIndustry: (state, action: PayloadAction<IIndustry | undefined>) => {
      state.selected = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
        .addCase(fetchIndustries.pending, (state) => {
          state.status = LOAD_PROGRESS;
        })
        .addCase(fetchIndustries.fulfilled, (state, action) => {
          state.industries[action.meta.arg] = action.payload;
          state.status = LOAD_SUCCESS;
        })
        .addCase(fetchIndustries.rejected, (state, action) => {
          state.status = LOAD_FAILED;
          state.error = action.error.message || 'Failed to load industries';
        });
  },
});

export default industrySlice.reducer;

export const selectAllIndustries = (state: IStore) => state.industries.industries;

export const selectIndustriesForGroupId = createSelector(
    [selectAllIndustries, (state: IStore, groupId: string | null) => groupId],
    (industries, groupId) => {
      if (groupId) {
        return industries[groupId] || [];
      } else {
        return [];
      }
    }
);

export const { setSelectedIndustry } = industrySlice.actions;
