import React, {useCallback, useEffect, useState} from "react";
import './SearchResults.scss';
import {Link, useNavigate} from "react-router-dom";
import {ISearchResults} from "../../../../interfaces/SearchResults.interface";

interface IResult {
  myResults: ISearchResults[];
  pattern: string;
  onSelect: () => void;
}

const SearchResults: React.FC<IResult> = ({myResults, pattern, onSelect}) => {
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);

  const handleMouseEnter = (index: number) => {
    setActiveIndex(index);
  }

  const handleClick = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    onSelect();
    navigate(`/company/${myResults[activeIndex].ticker}`);
  }, [activeIndex, myResults, navigate, onSelect])

  useEffect(() => {
    const KEY_DOWN = 'ArrowDown';
    const KEY_UP = 'ArrowUp';
    const KEY_ENTER = 'Enter';

    const handleKeyDownEvent = (e: KeyboardEvent) => {
      e.preventDefault();
      setActiveIndex((prevActiveIndex) => (
          prevActiveIndex === myResults.length - 1 ? 0 : prevActiveIndex + 1
      ));
    };

    const handleKeyUpEvent = (e: KeyboardEvent) => {
      e.preventDefault();
      setActiveIndex((prevActiveIndex) => (
          prevActiveIndex === 0 ? myResults.length - 1 : prevActiveIndex - 1
      ));
    };

    const handleKeyEnterEvent = (e: KeyboardEvent) => {
      e.preventDefault();
      handleClick(e as any); // Cast to any as handleClick expects a React.KeyboardEvent
    };

    const handleKeyNavigation = (e: KeyboardEvent) => {
      switch (e.key) {
        case KEY_DOWN:
          handleKeyDownEvent(e);
          break;
        case KEY_UP:
          handleKeyUpEvent(e);
          break;
        case KEY_ENTER:
          handleKeyEnterEvent(e);
          break;
      }
    };

    window.addEventListener('keydown', handleKeyNavigation);
    return () => {
      window.removeEventListener('keydown', handleKeyNavigation);
    }
  }, [myResults.length, activeIndex, handleClick]);

  function Result ({result, isActive, index}: {result: ISearchResults, isActive: boolean, index: number}) {
    return (
      <li className={isActive ? 'active' : undefined}>
        <Link to={`/company/${result.ticker}`} onClick={(e) => {handleClick(e)}} onMouseEnter={()=>handleMouseEnter(index)}>
          {result.name}
        </Link>
      </li>
    )
  }

  if (pattern.length > 0) {
    if (myResults.length) {
      return (
        <div  className={'results-wrapper'}>
          <div className={'results'}>
            <ul className={'list-unstyled'}>
              {
                myResults.map((result, index) => (
                  <Result key={result.id} result={result} isActive={index === activeIndex} index={index}/>
                ))
              }
            </ul>
          </div>
        </div>
      );
    } else {
      return (
        <div className={'results-wrapper' }>
          <p className={'info'}>Your search didn't return any results.</p>
        </div>
      );
    }
  }
}

export default SearchResults;
